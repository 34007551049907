import React from "react";

import Button from "@mui/material/Button";

import "./emBreveComponent.css";

const EmBreveComponent = (props)=> {
return (
<div className="emBreve">
<Button>
Livro completo em breve!
</Button>
</div>
);
};

export default EmBreveComponent;
